import React, { useRef } from 'react';
import { styled } from 'linaria/react';
import 'swiper/swiper-bundle.min.css';
import { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Above } from '@jetshop/ui/Breakpoints';
import { theme } from '../../Theme';
import { ReactComponent as Left } from '../../../svg/left.svg';
import { ReactComponent as Right } from '../../../svg/right.svg';

const SharedSwiperWrapper = styled('div')`
  width: 100%;
  max-width: 100vw;
  position: relative;
  .swiper-button-prev,
  .swiper-button-next {
    width: 54px;
    height: 54px;
    border-radius: 50%;
    background-color: ${theme.colors.grey};
    top: 35%;
    :after {
      display: none;
    }
  }

  .swiper-button-prev {
    left: -20px;
  }

  .swiper-button-next {
    right: 20px;
  }

  .swiper-pagination {
    display: flex;
    background: ${theme.baseColors.white};
    bottom: 0;
  }

  .swiper-pagination-bullet {
    flex: 1;
    height: 3px;
    background: ${theme.baseColors.white};
    opacity: 1;
    border-radius: 0;
    margin: 0 !important;

    &.swiper-pagination-bullet-active {
      background: ${theme.baseColors.black};
    }
  }

  .flex-row {
    display: flex;
    width: 100%;
    padding-left: ${props => (props.length <= 4 ? '0px' : '40px')};

    .product-card {
      max-width: 25%;
      width: 100%;
    }
  }
`;

export const SharedSwiper = ({
  children,
  loop = true,
  full,
  shouldAutoplay,
  slidesPerView,
  gap = 0
}) => {
  const swiperRef = useRef(null);

  const handlePrevClick = () => {
    swiperRef?.current?.swiper?.slidePrev();
  };
  const handleNextClick = () => {
    swiperRef?.current?.swiper?.slideNext();
  };

  return (
    <>
      <SharedSwiperWrapper
        className="shared-swiper-wrapper"
        length={children?.length ?? 1}
      >
        <Above breakpoint="lg">
          {matches => {
            return (
              <>
                {matches && children?.length <= slidesPerView ? (
                  <ul className="flex-row">
                    {children?.map((child, index) => (
                      <React.Fragment key={'shared-swiper-slide-' + index}>
                        {child}
                      </React.Fragment>
                    ))}
                  </ul>
                ) : (
                  <>
                    <Swiper
                      className="shared-swiper"
                      ref={swiperRef}
                      effect={'cover'}
                      grabCursor={shouldAutoplay ? false : true}
                      allowTouchMove={shouldAutoplay ? false : true}
                      threshold={2} // Resolves issue when swiper slides instead of click
                      centeredSlides={false}
                      loop={loop}
                      autoplay={shouldAutoplay && { delay: 5000 }}
                      speed={shouldAutoplay && 800}
                      spaceBetween={gap} // in px
                      slidesPerView={matches ? slidesPerView : 1.3}
                    >
                      {children?.map((child, index) => (
                        <SwiperSlide
                          key={'shared-swiper-slide-' + index}
                          className={'swiper-slide'}
                        >
                          {child}
                        </SwiperSlide>
                      ))}
                    </Swiper>
                    <div
                      className="swiper-button-prev"
                      onClick={() => handlePrevClick()}
                    >
                      <Left />
                    </div>
                    {(loop || children?.length > slidesPerView) && matches && (
                      <div
                        className="swiper-button-next"
                        onClick={() => handleNextClick()}
                      >
                        <Right />
                      </div>
                    )}
                  </>
                )}
              </>
            );
          }}
        </Above>
      </SharedSwiperWrapper>
    </>
  );
};
