import React from 'react';
import { css, cx } from 'linaria';
import t from '@jetshop/intl';
import ButtonWithLoading from '../../../ui/Button';
import { theme } from '../../../Theme';

export function VariantSelectorProductCard({
  product,
  variantHandler,
  showValidation,
  disableOutOfStock
}) {
  return (
    <div className={sharedStyles}>
      {product?.variants?.options?.map((option, index) => {
        let showValidationMessage = false;
        if (
          showValidation &&
          variantHandler.getMissingOptions()?.includes(option)
        ) {
          showValidationMessage = true;
        }

        return (
          <ButtonSelect
            option={option}
            variantHandler={variantHandler}
            key={option.name}
            showValidationMessage={showValidationMessage}
            disableOutOfStock={disableOutOfStock}
            doNotDisable={index === 0}
          />
        );
      })}
    </div>
  );
}

const sharedStyles = css`
  margin-bottom: 10px;

  &:empty {
    min-height: 41px;
  }
  label {
    display: flex;
    padding-bottom: 0.25em;
    margin-top: 1em;
    align-items: center;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }
  .invalid {
    label {
      color: #eb5757;
    }
  }
  .missingVariant {
    margin-right: 0.25em;
    height: 10px;
    width: 10px;
  }
`;

function ButtonSelect({
  option,
  variantHandler,
  showValidationMessage,
  disableOutOfStock,
  doNotDisable
}) {
  const { getSelectedValue, validateSelection, selectValue } = variantHandler;
  const selectedValue = getSelectedValue(null);
  // const t = useIntl();

  return (
    <div className={cx(buttonSelectStyles, showValidationMessage && 'invalid')}>
      {/* <label>{t('Select {optionName}', { optionName: option.name })}</label> */}
      {/* <label>{t("Available variants")}</label> */}
      {option?.values?.map(value => {
        const validation = validateSelection(value, option);
        return (
          <ButtonWithLoading
            data-testid={option.name + value}
            key={option.name + value}
            onClick={() => selectValue(value, option)}
            disabled={
              !doNotDisable &&
              (validation === 'invalid' ||
                (disableOutOfStock && validation === 'outOfStock'))
            }
            secondary={value !== selectedValue}
            aria-pressed={value === selectedValue}
          >
            {value}
          </ButtonWithLoading>
        );
      })}
    </div>
  );
}

const buttonSelectStyles = css`
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  overflow-x: auto;
  column-gap: 0.5em;
  margin-top: 1em;
  button {
    font-size: 12px !important;
    background: #282828 !important;
    border: 0 !important;
    padding: 0 !important;
    height: 25px !important;
    min-width: 60px !important;
    border-radius: ${theme.button.desktop.borderRadius} !important;
    &.secondary {
      background: #e9e5df !important;
    }
  }
  &.invalid button {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
`;
