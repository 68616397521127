import React, { useState, useEffect } from 'react';
import { cx } from 'linaria';
import {
  ClickToClose,
  categoryMenuDesktopStyle,
  desktopHeaderButtonStyles,
  desktopHeaderStyles,
  searchResultDesktopStyle,
  headerTopBannerStyle
} from './DesktopHeader.style';

import { Link } from 'react-router-dom';
import t, { useIntl } from '@jetshop/intl';
import { useHistory } from 'react-router-dom';
import { CTA, useModalManagement } from 'r3-lib/dist/r3lib';
import { SVG } from '../../../../assets/SVG';
import { StyledSearchBar } from '../../SearchBar/SearchBar';
import { headerIconStyle } from '../../../ui/styles/HeaderIcon.style';
import { StyledHeaderDropdownCategory } from '../HeaderDropdownCategory/HeaderDropdownCategory';
import { StyledHeaderDropdown } from '../HeaderDropdown/HeaderDropdown';
import { barHoverStyle } from '../../../ui/styles/BarHover.style';
import { SearchResult } from '../../SearchResult/SearchResult';
import { SearchResultProductCard } from '../../SearchResult/SearchResultProductCard/SearchResultProductCard';
import CategoryLink from '@jetshop/ui/CategoryLink';
import { headerDropdowCategoryStyle } from '../HeaderDropdownCategory/HeaderDropdownCategory.style';
import { FlyoutTrigger } from '../../../ui/Flyout/FlyoutTrigger';
import { Flyout } from '../../../ui/Flyout/Flyout';
import { FlyoutCartLayout } from '../../../ui/Flyout/FlyoutCartLayout';
import Badge from '../../../ui/Badge';
import { DynamicCategoryRenderer } from '../../../ContentEditor/DynamicCategoryRenderer/DynamicCategoryRenderer';
import {
  campaignbarComponents,
  menuComponents
} from '../../../ContentEditor/ContentComponents';
// import HeaderChannelSelector from '../ChannelSelector/ChannelSelector';

export const DesktopHeader = ({
  categories,
  favouritesCount,
  routes,
  cartData,
  style,
  headerVariant
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchOpen, setSearchOpen] = useState(false);
  const [activeCategory, setActiveCategory] = useState();
  const translate = useIntl();
  const history = useHistory();
  const [scrollDir, setScrollDir] = useState('scrolling down');
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const threshold = 0;
    let lastScrollY = window.scrollY;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.scrollY;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      setScrollDir(scrollY > lastScrollY ? 'scrolling down' : 'scrolling up');
      setScrollY(scrollY);
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollDir]);

  const { result, cart } = cartData;

  const { closeAll } = useModalManagement();

  const searchPlaceholderString = translate(
    'Search for product or category...'
  );

  const seeAllString = translate('See all');
  const newsString = translate('News');

  const isBorstiq =
    process.env.REACT_APP_LOGO_NAME === 'Borstiq' ? true : false;

  const handleMenuButtonClick = category => {
    closeAll();
    setSearchValue('');
    setActiveCategory(category);
    setMenuOpen(activeCategory?.id === category?.id ? menuOpen : true);
  };
  const handleMenuClose = () => {
    closeAll();
    // setSearchValue('');
    setActiveCategory(false);
    setMenuOpen(false);
  };

  const handleSearchProductClicked = () => {
    closeAll();
    setSearchValue('');
  };

  const handleSetMenuOpen = () => {
    setSearchValue('');
    setMenuOpen(false);
  };

  return (
    <>
      <div className={cx('header-top-banner', headerTopBannerStyle)}>
        <DynamicCategoryRenderer
          categoryId={process?.env?.REACT_APP_CAMPAIGNBAR_CATEGORY_ID}
          rendererComponents={campaignbarComponents}
        />
      </div>
      <div
        className={cx(
          headerVariant === 'special' && !isBorstiq && 'special',
          isBorstiq && 'borstiq',
          'desktop-header',
          style,
          scrollDir === 'scrolling up' && 'sticky',
          scrollY > 150 && 'scrolled'
        )}
        onMouseLeave={() => handleMenuClose()}
      >
        <div className="top-wrapper">
          {headerVariant === 'standard' && (
            <div className="logo-wrapper">
              <div>
                <a href="/">
                  <SVG name={process?.env?.REACT_APP_LOGO_NAME} />
                </a>
              </div>
            </div>
          )}
          <div className="left">
            <StyledSearchBar
              placeholder={searchPlaceholderString}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              keyActions={{
                Escape: () => {
                  console.log('ESC button action triggered');
                },
                Enter: () => {
                  console.log('ENTER button action triggered');

                  history?.push(`${routes?.search?.path}/?term=${searchValue}`);
                  setSearchValue('');
                }
              }}
              onFocus={() => {
                setMenuOpen(false);
                handleMenuClose();
                closeAll();
              }}
              onClick={() => {
                setMenuOpen(false);
                handleMenuClose();
                closeAll();
              }}
            />
          </div>
          {headerVariant === 'special' && (
            <div className="logo-wrapper">
              <div>
                <a href="/">
                  <SVG name={process?.env?.REACT_APP_LOGO_NAME} />
                </a>
              </div>
            </div>
          )}
          <div className="right">
            {/* <HeaderChannelSelector /> */}
            <Link to="/inkopslista">
              <div
                style={{ position: 'relative' }}
                onClick={() => setSearchValue('')}
              >
                <SVG
                  name={'Heart'}
                  className={headerIconStyle}
                  style={{ height: '18px' }}
                />
                <Badge text={favouritesCount} />
              </div>
            </Link>
            {/* <Link to={routes?.myPages?.path} onClick={() => setSearchValue('')}>
              <SVG name={'Person'} className={headerIconStyle} />
            </Link> */}

            <FlyoutTrigger triggerId={'header-cart-desktop'}>
              {drawer => (
                <div style={{ position: 'relative' }}>
                  <SVG
                    name={'Cart'}
                    className={headerIconStyle}
                    onClick={() => {
                      setSearchValue('');
                      setMenuOpen(false);
                      drawer.isOpen ? drawer.hideTarget() : drawer.showTarget();
                    }}
                  />
                  <Badge text={cart?.totalQuantity} />
                </div>
              )}
            </FlyoutTrigger>
          </div>
        </div>
        <div
          className="categories-and-links"
          style={{
            display: searchValue?.length >= 3 ? 'none' : 'flex'
          }}
        >
          <div className="left">
            {categories?.map((category, index) => {
              if (category?.id === 177 || !category.hasSubcategories) {
                return (
                  <CategoryLink
                    key={index + '-' + category?.id}
                    category={category}
                    onClick={() => {
                      setMenuOpen(false);
                      handleMenuClose();
                    }}
                    onMouseEnter={() => {
                      handleMenuClose();
                    }}
                    className={cx(
                      'header-category-button',
                      style && desktopHeaderButtonStyles
                    )}
                  >
                    <div
                      className={cx(
                        style && barHoverStyle,
                        activeCategory?.id === category?.id && 'active'
                      )}
                    >
                      {category?.name}
                    </div>
                  </CategoryLink>
                );
              }
              return (
                <CategoryLink
                  key={index + '-' + category?.id}
                  category={category}
                  onMouseEnter={() => {
                    handleMenuButtonClick(category);
                  }}
                  onClick={() => {
                    setMenuOpen(false);
                    handleMenuClose();
                  }}
                  className={cx(
                    'header-category-button',
                    style && desktopHeaderButtonStyles
                  )}
                >
                  <div
                    className={cx(
                      style && barHoverStyle,
                      activeCategory?.id === category?.id && 'active'
                    )}
                  >
                    {category?.name}
                  </div>
                </CategoryLink>
              );
            })}
          </div>
          <div className="right">
            {/* <Link
              to={`/${process?.env?.REACT_APP_ARTICLES_NAME}`}
              className={cx(
                'header-category-button',
                style && desktopHeaderButtonStyles
              )}
              onMouseEnter={() => {
                handleMenuButtonClick('');
                setMenuOpen(false);
              }}
              onClick={() => {
                handleMenuClose();
              }}
            >
              {t('Stable Stories')}
            </Link> */}
            <Link
              to={process.env.REACT_APP_CUSTOMER_SERVICE_LINK}
              className={cx(
                'header-category-button',
                style && desktopHeaderButtonStyles
              )}
              onMouseEnter={() => {
                handleMenuButtonClick('');
                setMenuOpen(false);
              }}
              onClick={() => {
                handleMenuClose();
              }}
            >
              {t('Contact us')}
            </Link>
          </div>
        </div>
        <StyledHeaderDropdown open={menuOpen} setOpen={menuOpen}>
          <div
            className={cx(
              'category-menu',
              categoryMenuDesktopStyle,
              !menuOpen && 'hidden'
            )}
          >
            <div className="custom">
              {activeCategory && menuOpen && (
                <>
                  <Link
                    to={activeCategory?.primaryRoute?.path ?? '/'}
                    onClick={() => {
                      handleMenuButtonClick(activeCategory);
                      handleMenuClose();
                    }}
                    className={cx('category-title')}
                  >{`${seeAllString} ${activeCategory?.name}`}</Link>
                  {/* TODO - go to category with parameters ?  */}
                  <Link
                    to={activeCategory?.primaryRoute?.path ?? '/'}
                    onClick={() => {
                      handleMenuButtonClick(activeCategory);
                      handleMenuClose();
                    }}
                    className="category-title"
                  >
                    {newsString}
                  </Link>
                </>
              )}
            </div>
            <div className="category-menu-result">
              {categories?.map((cat, index) => (
                <div
                  key={cat?.id + index}
                  style={{
                    display: cat?.id === activeCategory?.id ? 'block' : 'none'
                  }}
                >
                  {cat?.subcategories?.map((lvl2, index) => (
                    <StyledHeaderDropdownCategory
                      key={index}
                      category={lvl2}
                      setOpen={handleSetMenuOpen}
                      close={handleMenuClose}
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>
          {activeCategory && menuOpen && (
            <DynamicCategoryRenderer
              categoryId={activeCategory?.id}
              rendererComponents={menuComponents}
            />
          )}
        </StyledHeaderDropdown>
        {searchValue?.length >= 3 && (
          <SearchResult searchValue={searchValue}>
            {searchResult => {
              const autoComplete = searchResult?.data?.searchAutoComplete;
              setSearchOpen(!!autoComplete);
              return (
                <StyledHeaderDropdown open={searchOpen} setOpen={setSearchOpen}>
                  <div
                    className={cx('search-result', searchResultDesktopStyle)}
                  >
                    {searchResult?.loading ? (
                      'loading..' // TODO - Add loader
                    ) : (
                      <div className="results">
                        <div className="left">
                          <div
                            className={cx(
                              'categories',
                              headerDropdowCategoryStyle
                            )}
                          >
                            <Link
                              to={`${routes?.search?.path}/?term=${searchValue}`}
                              onClick={() => {
                                setSearchValue('');
                              }}
                              className="search-title"
                            >
                              {/* TODO- Check translation */}
                              {t('You searched for: ')} "{searchValue}"
                            </Link>
                            {autoComplete?.categories?.result?.length > 0 && (
                              <h4>{t('Categories')}</h4>
                            )}

                            {autoComplete?.categories?.result?.map(
                              (cat, index) => {
                                const displayText =
                                  parentsToString(cat?.primaryRoute?.parents) +
                                  cat?.name;
                                return (
                                  <CategoryLink
                                    key={index + '-auto-complete-' + cat?.id}
                                    category={cat}
                                  >
                                    {getHighlightedText(
                                      displayText,
                                      searchValue
                                    )}
                                  </CategoryLink>
                                );
                              }
                            )}
                          </div>
                          <CTA
                            link={`${routes?.search?.path}/?term=${searchValue}`}
                            className={cx('accent', 'show-all-hits-btn')}
                            clickCallback={handleSearchProductClicked}
                          >
                            {t('Show full result')}
                          </CTA>
                        </div>
                        <div className="right">
                          <div className="search-products">
                            {/* only show 8 products */}
                            {autoComplete?.products?.result
                              ?.slice(0, 8)
                              ?.map(product => {
                                return (
                                  <SearchResultProductCard
                                    key={'auto-complete-' + product?.id}
                                    product={product}
                                    clickCallback={handleSearchProductClicked}
                                  />
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </StyledHeaderDropdown>
              );
            }}
          </SearchResult>
        )}

        <Flyout
          targetId={'header-cart-desktop'}
          left={false}
          size={450}
          cart={true}
        >
          {drawer => (
            <>
              <FlyoutCartLayout
                close={drawer?.hideTarget}
                result={result}
                cart={cart}
              />
            </>
          )}
        </Flyout>
      </div>
      {searchValue && <ClickToClose onClick={() => setSearchValue('')} />}
    </>
  );
};

export const StyledDesktopHeader = ({
  categories,
  routes,
  cartData,
  favouritesCount,
  headerVariant
}) => {
  return (
    <DesktopHeader
      categories={categories}
      routes={routes}
      cartData={cartData}
      style={desktopHeaderStyles}
      favouritesCount={favouritesCount}
      headerVariant={headerVariant}
    />
  );
};

const parentsToString = parents => {
  if (!parents || parents.length === 0) return '';
  let sortedParents =
    parents.length > 1
      ? [...parents].sort((a, b) => {
          if (a.id.length < b.id.length) {
            return -1;
          } else {
            return 1;
          }
        })
      : parents;
  return (
    sortedParents
      ?.map(parent => parent?.object?.breadcrumbText ?? [])
      .join(' > ') + ' > '
  );
};

const getHighlightedText = (text, highlight) => {
  // Split on higlight term and include term into parts, ignore case
  const parts = text.split(
    new RegExp(
      `(${escapeRegExp(highlight)
        .split(' ')
        .join('|')})`,
      'gi'
    )
  );
  const highlightArray = highlight.split(' ');
  const toHighlightLower = highlightArray?.map(word => word.toLowerCase());
  return parts.reduce((prev, curr, index) => {
    return [
      ...prev,
      toHighlightLower.includes(curr.toLowerCase()) ? (
        <strong
          style={{
            fontWeight: 600
          }}
          key={index}
        >
          {curr}
        </strong>
      ) : (
        curr
      )
    ];
  }, []);
};

const escapeRegExp = string => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};
