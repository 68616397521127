import { css } from 'linaria';

import AvertaStdWoff from './AvertaStd-Regular.woff';
import AvertaStdWoff2 from './AvertaStd-Regular.woff2';

import AvertaStdWoffBold from './AvertaStd-Bold.woff';
import AvertaStdWoff2Bold from './AvertaStd-Bold.woff2';

import AvertaStdWoffSemiBold from './AvertaStd-Semibold.woff';
import AvertaStdWoff2SemiBold from './AvertaStd-Semibold.woff2';

// Google Fonts downloaded from http://google-webfonts-helper.herokuapp.com/fonts/sarabun?subsets=latin

export default function loadCss() {
  return css`
    :global(html) {
      /* Regular font */
      @font-face {
        font-family: 'Averta Std';
        src: local('Averta Std Regular'), local('Averta Std-Regular'),
          url(${AvertaStdWoff2}) format('woff2'),
          url(${AvertaStdWoff}) format('woff');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }

      /* Bold font */
      @font-face {
        font-family: 'Averta Std';
        src: local('Averta Std Bold'), local('Averta Std-Bold'),
          url(${AvertaStdWoff2Bold}) format('woff2'),
          url(${AvertaStdWoffBold}) format('woff');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: 'Averta Std';
        src: local('Averta Std Bold'), local('Averta Std-Bold'),
          url(${AvertaStdWoff2SemiBold}) format('woff2'),
          url(${AvertaStdWoffSemiBold}) format('woff');
        font-weight: 600;
        font-style: normal;
        font-display: swap;
      }
    }
  `;
}
