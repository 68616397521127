import { css } from 'linaria';
import { theme } from '../../../Theme';
import { styled } from 'linaria/react';

export const headerTopBannerStyle = css`
  .campaign-bar-item-inner {
    margin-top: 0 !important;
  }
`;

export const desktopHeaderStyles = css`
  &.borstiq {
    background-color: ${theme.colors.layout.headerBackground};
    .header-category-button {
      background-color: ${theme.colors.layout.headerBackground};
    }
    .top-wrapper {
      height: 63px;
      position: relative;
      border-bottom: 1px solid #E7DED0;
    }

    .logo-wrapper {
      position: absolute;
      left: calc(50% - 60px);
      top: 10px;
      background-color: ${theme.colors.layout.headerBackground};
      z-index: 1;
      padding: 0 4px;
    }
  }

  &.special {
    .top-wrapper {
      height: 63px;
      position: relative;
    }
    .logo-wrapper {
      position: absolute;
      left: calc(50% - 70px);
      background-color: ${theme.colors.layout.headerBackground};
      z-index: 1;
      padding: 0 4px;
    }

  }

  width: 100%;
  background-color: ${theme.colors.white};
  z-index: 1000;
  display: block;
  position: relative;
  top: 0;
  transition: all, 0.2s ease;

  &.sticky {
    opacity: 1 !important;
    position: sticky;
  }
  &.scrolled {
    opacity: 0;
  }

  .top-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    background-color: ${theme.colors.layout.headerBackground};
  }

  .logo-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 63px;

    /* .left {
      display: flex;
      align-items: center;
      gap: 30px;

      .Willab {
        height: 50px;
        color: ${theme.colors.black};
      }
    } */
  }

  .right {
    display: flex;
    min-width: 170px;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    .link-evaluator {
      height: fit-content;
      text-decoration: none;
    }
  }

  .categories-and-links {
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 40px;
    padding: 0 20px;
    border-top: 1px solid ${theme.colors.layout.border};
    border-bottom: 1px solid ${theme.colors.layout.border};

    .left {
      display: flex;
      gap: 20px;
    }
    .right {
      display: flex;
      gap: 20px;
    }
  }
`;

export const ClickToClose = styled('div')`
  position: absolute;
  background-color: rgba(255, 255, 255, 0.6);
  height: 100vh;
  width: 100vw;
  z-index: 100;
`;

export const desktopHeaderButtonStyles = css`
  position: relative;
  padding: 5px 0;
  background: white;
  color: ${theme.colors.black};
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  ${theme.fonts.desktop.p2}
  line-height: 100%;
  font-weight: ${theme.fontWeights.semiBold};
  text-transform: uppercase;
  letter-spacing: 0.1em;
  ${theme.below.lg} {
    ${theme.fonts.mobile.p2}
    line-height: 100%;
    font-weight: ${theme.fontWeights.medium};
  }
`;

export const categoryMenuDesktopStyle = css`
  display: flex;
  /* flex-direction: column; */

  .custom {
    border-left: 1px solid ${theme.colors.layout.border};
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    min-width: 250px;
  }
  .category-title {
    color: ${theme.colors.black};
    text-decoration: none;
    ${theme.fonts.desktop.h4};
  }

  .category-menu-result {
    border-left: 1px solid ${theme.colors.layout.border};
    columns: 4;
    column-rule: 1px solid ${theme.colors.layout.border};
  }

  .lvl-4 {
    display: none;
  }
`;

export const searchResultDesktopStyle = css`
width: 100%;

  .search-title {
    display: block;
    color: ${theme.colors.black};
    text-decoration: none;
    font-size: 20px;
    line-height: 24px;
    font-weight: ${theme.fontWeights.bold};
    margin-bottom: 22px!important;
  }

  .results {
    /* margin-top: 36px; */
    /* gap: 100px; */
    display: grid;
    grid-template-columns: 1fr 2fr;

       h4 {
        ${theme.fonts.desktop.p1}
        font-weight: ${theme.fontWeights.bold};
        line-height: 180%;
      }

    .left {
      width: max-content;
      min-width: 350px;
      white-space: nowrap;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: ${theme.colors.white};
      padding: 3rem;
      height: auto;

      .categories {
        display: flex!important;
        flex-direction: column;
      }

      a {
        text-decoration: none;
      }

      .cta {
        margin-top: 20px;
        justify-self: flex-end;
        text-transform: uppercase;
      }

      .cta.show-all-hits-btn{
        height: 45px;
        width: 100%;
      }

    }

    .right {
      /* width: 100%; */
      padding: 3rem;
      background-color: #f8f7f5;

      .search-products{
        width: max-content;
        column-count: 2;
        > * {
          width: 100%;
        }
      }
      [data-flight-image-container] {
        mix-blend-mode: multiply;
        width: 75px!important;
        height: 75px!important;
      }

    }
  }
`;
